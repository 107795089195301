.media-page {
  margin-top: 20px;
}

.category-selector {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.media-card {
  margin-bottom: 20px;
  text-align: center;
}

.media-card .icon {
  margin-bottom: 15px;
  color: #007bff;
}
