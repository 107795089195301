.admin-users-page {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.users-table th,
.users-table td {
  text-align: left;
  padding: 10px;
}

.text-center {
  text-align: center;
}
