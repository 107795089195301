.product-page {
  padding: 20px;
  text-align: center;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}
