.customer-offer-page {
  font-family: Arial, sans-serif;
}

.customer-offer-page .sidebar {
  border-right: 1px solid #ddd;
}

.customer-offer-page .logo {
  max-width: 100%;
  height: auto;
}

.customer-offer-page .sigCanvas {
  border: 1px solid #ccc;
}

.customer-offer-page .actions button {
  width: 150px;
}

.customer-offer-page .card-header {
  background: #23d17a;
  padding: 5px 10px;
}

.customer-offer-page .card-header h5 {
  font-size: 14px;
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
  color: #fff;
}

.customer-offer-page .card-body p {
  font-size: 12px;
}

.customer-offer-page .offer-details-list ul li {
  font-size: 12px;
}

.customer-offer-page .offer-details-list ul {
  padding-left: 10px;
  margin: 0;
}

.customer-offer-page .sidebar .card {
  width: 100%;
  margin-bottom: 15px;
}

.customer-offer-page .sidebar {
  background: #f4f4f4;
}

.customer-offer-page .sidebar .logo {
  text-align: center;
  width: 160px;
  margin: 0;
}

.sigCanvas {
  border: 1px solid #ccc;
  display: block;
  margin: 0 auto;
}

.signature-modal .modal-body {
  text-align: center;
}

.customer-offer-page .offer-details {
  position: relative;
  height: 100vh;
  padding: 0 20px;
  /* Adjust based on adviser-info height */
}
/* 
.pdf-iframe {
  border: none;
  width: 100%;
  height: calc(100vh - 100px);
  border: none;

}
.pdf-iframe {
  width: 100%; 
  height: 80vh; 
  border: none;
} */

@media (max-width: 768px) {
  /* .pdf-iframe {
    height: 60vh; 
  } */
}

@media (max-width: 576px) {
  /* .pdf-iframe {
    height: 50vh; 
  } */
}
.adviser-info {
  padding: 0;
}

.adviser-info p {
  margin: 0;
}

.header-row .row .col {
  align-items: center;
  display: flex;
  height: 80px;
}

.fullscreen-sigCanvas {
  width: 100%;
  /* Full width of the viewport */
  height: 100%;
  /* Full height of the viewport */
  border: 1px solid #ccc;
  /* Optional border */
  box-sizing: border-box;
  /* Ensure padding and border are included in the size */
}
.offer-details {
  overflow: auto; /* Enable scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
