.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.overlay .progress-flow-container {
  margin: 20px auto;
  max-width: 600px;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
}

.overlay .timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.overlay .timeline-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.overlay .timeline-item.active {
  opacity: 1;
}

.overlay .icon-container {
  width: 40px;
  height: 40px;
  background: #23d17a;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  margin-right: 15px;
}

.overlay .message-container {
  flex: 1;
}

.overlay .timestamp {
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;
  display: block;
  min-height: 1.5rem;
  text-align: left;
}

.overlay p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.overlay .progress-bar {
  background: #eaeaea;
  border-radius: 5px;
  height: 8px;
  margin-top: 5px;
  overflow: hidden;
}

.overlay .progress {
  height: 100%;
  background: #ffa500; /* Soft orange */
  transition: width 0.2s ease;
}

.overlay .complete-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #23d17a;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overlay .complete-button:hover {
  background-color: #1bb067;
}
