.package-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 15px;
    text-align: center;
    transition: box-shadow 0.2s;
}

.package-card.selected {
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
}

.package-card .package-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.package-card .package-description {
    font-size: 14px;
    color: #555;
}

.package-buttons button {
    margin: 5px;
}

.package-buttons .secondary {
    background: #ddd;
    color: #333;
}

.package-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* gap: 20px; */
}

.package-description ul {
    text-align: left;
    padding-left: 45px;
}

.filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    position: relative;
}

.filters input,
.filters select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.filters input[type="number"] {
    max-width: 150px;
}

.inputs-address {
    max-width: 570px;
}

.package-card-title {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: -30px;
}

.package-card-title h3 {
    font-size: 18px !important;
}