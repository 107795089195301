body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.admin-panel {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.sidebar .logo {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.sidebar .menu ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.sidebar .menu ul li {
  width: 100%;
}

.sidebar .menu ul li a {
  color: #ecf0f1;
  text-decoration: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  width: 100%;
}

.sidebar .menu ul li a:hover,
.sidebar .menu ul li a.active {
  background-color: #34495e;
}

.admin-content {
  flex-grow: 1;
  background-color: #ecf0f1;
  overflow-y: auto;
}

.admin-content .content-area {
  padding: 20px;
}

.admin-title {
  font-weight: bold;
  font-size: 28px;
}

header.admin-header {
  background: #fff;
  padding: 20px 30px;
  box-shadow: 0 0px 12px 0px #e3e3e3;
}

.widget {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.widget-header h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.widget-content {
  margin-top: 16px;
  padding: 0 20px 20px;
}

.widget-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.filter-button {
  font-size: 18px;
}

.graph-content {
  margin-top: 20px;
}

.admin-offertes {
  padding: 20px;
  background-color: #f9f9f9;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: white;
}

.admin-table th,
.admin-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.clickable-row:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.offertes-filters input {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.sidebar .menu .has-submenu {
  position: relative;
  cursor: pointer;
}

.sidebar .menu .submenu-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
}

.sidebar .menu .submenu {
  margin-left: 20px;
  list-style: none;
  padding: 0;
  max-height: 0; /* Initially hidden */
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.sidebar .menu .submenu.open {
  max-height: 500px; /* Adjust based on the expected height */
  opacity: 1;
}

.sidebar .menu .submenu li {
  margin: 5px 0;
}

.sidebar .menu .submenu li a {
  font-size: 14px;
  color: #ccc;
  text-decoration: none;
}

.sidebar .menu .submenu li a.active {
  font-weight: bold;
  color: #fff;
}
