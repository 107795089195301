@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";

/* General Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

h2 {
  font-weight: bold !important;
  margin-bottom: 30px !important;
}
.mb-4.logs.card .card-body {
  max-height: 400px;
  overflow-y: auto;
}
.display-flex {
  display: flex;
  gap: 30px;
}
body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* Prevents horizontal scrolling */
  background-color: #f4f4f4 !important;
  font-family: Arial, sans-serif;
  color: #333;
  /* Dark Gray Text */
  width: 100%;
  height: 100%;
}
.faq-card.card {
  min-height: 200px;
}
#root {
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  display: flex;
}
.select-item {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  margin-bottom: 10px;
  min-height: 68px;
  padding: 0 10px;
}

.select-item label {
  margin: 0;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}
.lead-selection {
  background: #f4f4f4;
  padding: 10px 20px;
  margin-top: 10px;
}
.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-decoration: none;
  color: #007bff;
}

.pagination .active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.widget .filters {
  padding-left: 20px;
  width: 100%;
}
.widget .filters .search-bar {
  max-width: 300px;
  width: 100%;
}
.sticky-div {
  position: sticky;
  top: 10px;
}
.amount-input span {
  display: inline-block;
  padding: 0 10px;
  font-size: 18px;
}
.accordion-step .product-category h5 {
  background: #959595;
}

.accordion-step .product-category {
  margin-bottom: 20px;
}
.main {
  width: 100%;
}
.product-category > h5 {
  background: #00c854;
  color: #fff;
  padding: 5px 10px;
  text-transform: capitalize;
}
/* App Container */
.app-container {
  flex: 1;
  max-width: 800px;
  margin: 10px auto 0;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 600px;
  position: relative;
}

.address-info {
  background: aliceblue;
  padding: 30px;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  /* White background */
  padding: 10px 20px;
  color: #333;
  /* Dark Gray Text */
  border-bottom: 2px solid #00c853;
  /* Green accent line */
}

.header .logo h1 {
  font-size: 1.5rem;
  color: #00c853;
  /* Green Text for Logo */
}

.nav {
  display: flex;
  gap: 20px;
  justify-content: right;
}

.nav ul {
  display: flex;
  list-style: none;
  gap: 20px;
}

.nav li a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav li a:hover {
  color: #00c853;
  /* Green hover effect */
}

/* Hamburger menu */
.hamburger {
  display: none;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.hamburger svg {
  font-size: 24px;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
}

.button-block > div {
  flex: 1;
  /* Allows equal spacing */
}

.button-block .back {
  text-align: left;
  background: grey;
}

.button-block .align-right {
  text-align: right;
}

.container {
  max-width: 74.714286rem !important;
  margin: 0 auto;
}

ul.menu-list li {
  display: flex;
  align-items: center;
}

.overview-container .card {
  position: sticky;
  top: 10px;
}

h5.mt-4.group-title {
  background: #35ab66;
  color: #fff;
  padding: 10px;
}

.product-line .text-end {
  text-align: right !important;
  align-items: center;
  display: flex;
  justify-content: right;
}

.accordion-step {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.accordion-step h3 {
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.menu-list a.active {
  color: #22b66e;
}

span.initials {
  display: flex;
  width: 25px;
  align-items: center;
  justify-content: center;
  height: 25px;
}

.accordion-step.active h3 {
  background: #d9f7d9;
}

.accordion-step .card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.accordion-step button {
  border-radius: 4px;
}

span.input-quantity {
  width: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.separator {
  display: block;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}

.separator.price {
  border-color: #cdcdcd;
}

.separator.divider {
  border-width: 5px;
}

.total-price {
  font-size: 21px;
}
.card-body .title-flex {
  max-width: 380px;
}
.status {
  position: absolute;
  right: 20px;
  top: 10px;
  background: #48e8398f;
  color: #1a7719;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status.Geannuleerd {
  background: #e8393978;
  color: #771919;
}
.status.concept {
  background: #cccccc;
  color: #333;
}

.modal-offerte .modal-content {
  height: 100%;
}

.modal-offerte {
  height: 95%;
}

p.pricing {
  font-size: 16px;
  display: flex;
}

p.pricing span {
  margin-left: auto;
}

.notes {
  margin-top: 20px;
}

.notes h3 {
  font-size: 18px;
  font-weight: bold;
}

.notes ul {
  list-style: circle;
  background: #f4f4f4;
  padding: 10px 10px 10px 40px;
}

.notes ul li span {
  display: block;
  font-weight: bold;
}

tbody tr:hover {
  cursor: pointer;
}

p.pricing.total-price {
  font-size: 18px;
  font-weight: bold;
}

.justify-content-right {
  justify-content: right;
}

.submit-buttons {
  display: flex;
  gap: 15px;
}

.modal-body img {
  max-height: 160px;
  object-fit: contain;
}

.modal-body.pdf img {
  max-height: 100%;
}

.buttons-block {
  display: flex;
}

.buttons-block .save-button {
  margin-left: auto;
}

.tags-list {
  display: flex;
  gap: 10px;
  padding: 0 10px 0 0;
}
.schouwer-info {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 13px;
  background: #f4f4f4;
  padding: 10px;
}
.tags-list .tag.danger {
  background: #ffd7d7;
}
.text-left canvas.sigCanvas {
  margin: 0;
}

.tags-list .tag {
  background: #f4f4f4;
  padding: 5px 15px;
  border-radius: 28px;
  margin-top: -20px;
  cursor: pointer;
}

.tags-list .tag.trans {
  background: #fff;
  cursor: default;
}

.tags-list .tag:hover {
  background: #dadada;
}

.tags-list .tag.trans:hover {
  background: #fff;
}

.dark.card-header {
  background: #1f1f1f !important;
}

/* Hamburger Menu - Mobile */
@media (max-width: 768px) {
  .hamburger {
    display: block;
    margin-left: auto;
  }

  .nav {
    display: none !important;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #ffffff;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border-top: 2px solid #00c853;
    z-index: 9999999;
  }

  #root .nav.open {
    display: flex !important;
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
}

/* Title */
.title {
  font-size: 2rem;
  text-align: center;
  color: #00c853;
  /* Matches logo color */
}

/* Section Styling */
.section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Buttons */
.send-button {
  width: 100%;
  padding: 10px;
  background-color: #00c853;
  /* Green Background */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #00a63f;
  /* Darker Green on Hover */
}
.korting-toepassen {
  font-size: 11px;
  font-weight: 500;
  color: green;
  cursor: pointer;
}

.korting-toepassen:hover {
  text-decoration: underline;
}
button {
  padding: 10px 15px;
  color: #fff;
  background-color: #00c853;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #00a63f;
}

/* Progress Bar */
.progress-bar-custom {
  width: 100%;
  height: 8px;
  background-color: #f3f3f3;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.progress-custom {
  height: 100%;
  background-color: #00c853;
  /* Green Progress */
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* Form Fields */
label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  color: #495057;
}
.modal .react-datepicker-wrapper {
  margin-bottom: 0 !important;
}
.selected-users {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
td.actions a,
.actions button {
  font-size: 10px;
  padding: 2px;
}
.modal-body .address-details {
  background: #179f0024;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 10px;
}
.selected-users .badge {
  background-color: #3498db;
  color: white;
  cursor: pointer;
}
.inner-header-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 10px;
  font-weight: bold;
}
.selected-users .badge:hover {
  background-color: #2980b9;
}
input[type="text"],
input[type="number"],
input[type="email"],
select,
input#password,
.select-input {
  width: 100% !important;
  padding: 10px !important;
  font-size: 1rem !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;

  font-size: 1rem !important;
  margin-top: 5px !important;
  margin-bottom: 15px !important;
}

input[type="checkbox"] {
  margin-right: 8px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
li.nav-item {
  cursor: pointer;
}
.overlay p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #007bff;
}
li.nav-item {
  cursor: pointer;
}

.title-flex {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.agent-block {
  background: #fff;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
}
.title-flex h2,
.title-flex h5 {
  margin-bottom: 0px !important;
}
p.error {
  color: #fff;
  background: #c54848;
  padding: 7px 10px;
  border-radius: 8px;
  font-weight: bold;
}
.warning {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  font-weight: bold;
  z-index: 9999;
}

/* Advis Rapport Section */
.advies-rapport {
  margin-top: 20px;
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
}
.logs .icon-button {
  margin-left: auto;
  background: #3ae893;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.logs h5 {
  display: flex;
}

.logs .icon-button:hover {
  background: #29c97b;
}
.timeline {
  position: relative;
  padding-left: 20px;
  border-left: 2px solid #e6e6e6;
  margin-left: 15px;
}

.timeline-item {
  position: relative;
  margin-bottom: 20px;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-icon {
  position: absolute;
  left: -36px;
  top: -5px;
  background-color: #23d17a;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.timeline-icon svg {
  color: #fff !important;
  margin-top: -3px;
}
.timeline-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.timeline-time {
  font-size: 12px;
  color: #666;
  margin-right: 10px;
}

.timeline-dot {
  width: 8px;
  height: 8px;
  background-color: #23d17a;
  border-radius: 50%;
  margin-right: 10px;
}

.timeline-body {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
}

.timeline-body strong {
  display: block;
  font-size: 14px;
  color: #333;
}

.timeline-details {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}
.action-buttons {
  display: flex;
  gap: 5px;
}
.datasheets {
  margin-top: 20px;
  background: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
}
ul.financing-options {
  list-style: none;
  padding: 0;
}

ul.financing-options li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.action-buttons .pay-condition {
  background: #ffffff;
  padding: 10px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
}
ul.financing-options .form-label span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
}

.table span.pill {
  background: #37e89129;
  margin-right: 5px;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 11px;
}
span.sign-date-table {
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  margin-top: 5px;
  display: inline-block;
}

span.sign-date-table > span {
  display: block;
  margin-top: 3px;
}
/* Mobile Styles */
@media (max-width: 768px) {
  div#root {
    display: flex;
    padding-top: 0px;
  }

  .title {
    font-size: 1.5rem;
  }

  .form-step {
    margin-top: 15px;
  }

  input,
  select,
  .react-datepicker-wrapper {
    font-size: 0.9rem;
    padding: 8px;
  }

  .progress-bar {
    height: 6px;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .title {
    font-size: 1.2rem;
  }

  .form-step {
    padding: 10px;
  }

  .button-block button {
    font-size: 0.8rem;
    padding: 8px;
  }

  .progress-bar {
    height: 5px;
  }
}
