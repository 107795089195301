.widget-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.widget-icon {
  font-size: 2rem;
}

.widget-title {
  font-size: 1rem;
  color: #6c757d;
}

.widget-count {
  font-size: 2rem;
  font-weight: bold;
}
