.start-page {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333; /* Aanpassen naar jullie stijl */
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  height: 80px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.options {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.option {
  border: 1px solid #ddd; /* Aanpassen naar jullie stijl */
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  text-align: center;
  transition: transform 0.3s;
  background: #fff;
}

.option:hover {
  transform: scale(1.05);
  border-color: #28a745; /* Accentkleur */
}

.option .icon {
  font-size: 32px;
  margin-bottom: 10px;
}

.option h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #00c854;
}
h2 {
  font-weight: bold;
  margin-bottom: 40px;
}

.icon svg {
  stroke: #00c854;
}

.option a {
  text-decoration: none;
}
.option p {
  font-size: 14px;
  color: #555; /* Aanpassen naar jullie stijl */
}
