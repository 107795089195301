.offerte-card {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.offertes-page {
  text-align: center;
  padding: 20px;
}

.mr-3 {
  margin: 0 5px;
}

p.pricing.discount {
  padding-left: 20px;
  margin-top: -1rem;
}

.manual-product {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
}

.text-middle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.filters button {
  padding: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  margin-top: 3px;
}

.filters button svg {
  font-size: 24px;
}

tr.clickable-tr {
  cursor: pointer;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: #fff !important;
}

.popup-overlay {
  top: 60px;
  position: absolute;
  background: #d6d6d6;
  padding: 20px;
  right: 0;
  min-width: 400px;
}

.popup-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.popup-actions {
  flex: 1 0 100%;
  /* margin-top: 40px; */
  display: flex;
  gap: 30px;
  justify-content: center;
}

.popup-actions button {
  padding: 20px 20px;
  width: 150px;
}
.offertes-page input.width-2x {
  flex: 1 0 40%;
}

.offertes-page .filters {
  max-width: 100%;
}
