.custom-modal .modal-header {
  border-bottom: 0;
  padding: 15px 16px 0;
}

.modal-dialog.custom-modal {
  margin-top: 0;
}

.custom-modal .modal-body {
  padding: 0 16px;
}

.custom-modal .modal-body label {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0;
}
