.logo-image img {
    width: 290px;
}

.logo-image {
    text-align: center;
    margin-top: 60px;
}

.app-container.login {
    max-width: 500px;
    min-height: 0;
}