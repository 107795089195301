.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}

.product-card.selected {
  border: 2px solid green;
  background-color: #f9fff9;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.product-card h3 {
  font-size: 1.2rem;
  margin: 10px 0;
}

.product-card p {
  font-size: 0.9rem;
  color: #666;
}

.product-card ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.product-card ul li {
  font-size: 0.9rem;
  color: #333;
}

.product-card h4 {
  font-size: 1.1rem;
  color: #2ecc71;
  margin: 10px 0;
  margin-top: auto;
}

.product-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.select-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.product-card button {
  margin-top: 16px;
  background-color: green;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}
.product-card button.info-button {
  background-color: #2c2c2c;
}
.product-card button:hover {
  background-color: darkgreen;
}
.select-button:hover {
  background-color: #27ae60;
}

.info-button {
  background-color: #ddd;
  color: #333;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.info-button:hover {
  background-color: #ccc;
}
