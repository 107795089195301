.agent-row {
  display: flex;
  gap: 5px;
}

.agent-row .agent-check {
  font-size: 12px;
  background: #fff;
  padding: 5px 10px;
}

.agent-row .agent-check .form-check {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.agent-row .agent-check .form-check .form-check-label {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.notes-block-lead {
  margin-top: 18px;
  background: #f4f4f4;
  border: 1px solid #d8d8d8;
  padding: 10px 20px;
}

.notes-block-lead .title {
  font-size: 15px;
  text-align: left;
  color: #000;
  font-weight: 500;
}
