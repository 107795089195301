.faq-page {
  margin-top: 20px;
}

.faq-page .list-group-item {
  cursor: pointer;
}

.faq-page .list-group-item.active {
  background-color: #007bff;
  color: #fff;
}

.faq-page .card {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.faq-page .card:hover {
  transform: scale(1.02);
}

.faq-page .img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}
.categories-faq {
  position: sticky;
  top: 10px;
}
