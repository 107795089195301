.package-item {
    margin-bottom: 10px;
}

.package-item.active {
    background: #d9f7d9;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}

.package-item label {
    margin: 0;
}

.quantity-controls {
    display: flex;
}

.quantity-controls input {
    width: 100px !important;
    margin: 0 10px !important;
}

.quantity-controls button {
    width: 50px;
    height: 50px;
}