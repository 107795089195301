.single-product-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.back-button button {
  background: #ddd;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-header {
  text-align: center;
}

.product-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.product-left {
  width: 65%;
}

.product-left h3 {
  margin-top: 20px;
}

.product-left ul {
  list-style: none;
  padding-left: 0;
}

.product-left ul li {
  margin-bottom: 10px;
}

.product-right {
  width: 30%;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
}

.product-right .offerte-button {
  margin: 20px 0;
  padding: 10px 20px;
  background: #00a63f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-right .offerte-button:hover {
  background: #007a2c;
}

.product-right ul {
  list-style: none;
  padding-left: 0;
}

.product-right ul li {
  margin-bottom: 10px;
}
