.form-step h2 {
  font-size: 24px;
  margin-bottom: 0px !important;
}
input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

.checkbox-aligned {
  display: flex;
  align-items: center;
  margin: 21px 0 0;
}

.checkbox-aligned label {
  margin: 0;
}
/* #root button {
  border-radius: 6px;
  min-width: 150px;
  text-align: center;
} */

#root button.backbutton {
  padding: 10px 25px;
  background-color: #494949;
}

button.backbutton svg {
  font-size: 22px;
}
.align-middle {
  vertical-align: middle !important;
  display: flex;
  align-items: center;
}
.addressinfo {
  border: 1px solid;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
}
span.clickable-text {
  color: #00c853;
  cursor: pointer;
}
.product-line {
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
  margin: 0 10px;
  display: flex;
}

.product-line:first-child {
  border-top: 1px solid #000000;
}

.product-line.line-0 {
  border-top: 1px solid #f4f4f4;
}

.product-line h5,
.product-line p,
.product-line label {
  margin: 0 !important;
}
.content-header {
  margin-bottom: 20px;
}
.container.content-header {
  margin-bottom: 20px;
}

/* #root button.btn.btn-sm {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 32px;
} */
.product-line button {
  min-width: 50px !important;
}
.container.app-container h2 {
  padding-left: 10px;
}
.product-overview img.overview-image {
  max-width: 50%;
  height: auto;
  margin: 0px auto 20px;
  border-radius: 4px;
  display: block;
}
.product-overview h5 {
  font-size: 21px;
}
