.products-page {
  padding: 20px;
  background-color: #f8f9fa;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* .product-card {
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
} */

.product-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
}

.details-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.details-button:hover {
  background-color: #0056b3;
}
button.secondary {
  background-color: #494949;
}
button.secondary:hover {
  background-color: #191919;
}
.product-page .button-block {
  margin-top: auto;
}
