.image-actions {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  padding: 10px;
  justify-content: center;
  height: 55px;
}

.image-actions a {
  font-size: 11px;
  text-decoration: none;
  color: #000;
}

.image-actions a:hover {
  text-decoration: underline;
}
h3.image-upload-title {
  margin-top: 10px;
  font-weight: bold;
  color: #495057;
  font-size: 16px;
}
label.image-actions.form-label {
  margin: 0;
}
.product-box {
  background: #f4f4f4;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.product-box img {
  width: 140px;
}

.product-box h3 {
  font-size: 16px;
  margin: 0px;
}
.is-invalid {
  border: 1px solid red;
}

.text-danger {
  color: red;
}
.dropdown-pills .badge {
  padding: 0.5em 1em;
  font-size: 0.9rem;
  border-radius: 1rem;
}
.dropdown-pills .badge:hover {
  background-color: #dc3545; /* Hover effect for removal */
  color: white;
}
label.image-actions.true.form-label {
  color: #ff5d5d;
}
.modal-schouw h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}
.ql-editor p {
  margin-bottom: 10px;
}
