ul.menu-list {
  margin: 0;
}

ul.menu-list.admin-menu {
  background: #aeaeae;
  margin: 0;
  padding: 0 20px;
}

ul.menu-list.admin-menu a {
  color: #fff;
}

.nav .menu-list {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav .menu-list li {
  position: relative;
}

.nav .dropdown {
  position: relative;
}

.nav .dropdown-toggler {
  background: #312e2e;
  border-radius: 50%;
  color: #fff;
  padding: 7px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nav .dropdown-menu {
  display: none;
  /* display: block; */
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 0 !important;
  list-style: none;
  min-width: 200px;
}

/* .nav .dropdown-menu li {
  padding: 0.8rem;
} */

.nav .dropdown-menu li a {
  padding: 0.8rem 1.5rem;
  width: 100%;
  /* background: #f4f4f4; */
}

.nav .dropdown-menu li.header-item {
  padding: 0.8rem 1.5rem;
}

.nav .dropdown-menu li a:hover,
li.header-item.logout:hover {
  background: #f4f4f4;
  color: #333;
  cursor: pointer;
}

.nav .dropdown-menu li button {
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0.5rem;
}

.nav .dropdown:hover .dropdown-menu {
  display: block;
}

.separator.dropdown {
  margin: 0;
}

.nav ul.menu-list a {
  font-size: 16px;
  font-weight: 500;
}

.nav ul.menu-list .dropdown a {
  font-size: 18px;
  font-weight: normal;
}
